import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "../components/RichText"
import ArticleCard from "../components/ArticleCard"
import { Breadcrumbs } from "../components/Breadcrumbs"

const CategoryTemplate = ({ data }) => {
  const category = data.contentfulCategory
  const articles = data.allContentfulArticle.nodes
  return (
    <Layout>
      <SEO title={category.name} description={category.summary.summary} />
      <Breadcrumbs shortTitle={category.name} urlPath={`/${category.slug}`} />
      <div className="article-container">
        <div className="cover-image">
          <img
            src={category.coverImage.fixed.src}
            alt={category.coverImage.title}
          />
        </div>
        <h1 className="section-title">{category.name}</h1>
        <summary>
          <RichText content={category.description} />
        </summary>
        <ul className="card-list">
          {articles.map((article, i) => (
            <ArticleCard article={article} key={i} />
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      slug
      name
      summary {
        summary
      }
      description {
        json
      }
      coverImage {
        fixed(width: 680, height: 450) {
          src
        }
        title
      }
    }
    allContentfulArticle(filter: { category: { id: { eq: $id } } }) {
      nodes {
        slug
        title
        category {
          slug
        }
        coverImage {
          fixed(width: 300, height: 200) {
            src
          }
          title
        }
      }
    }
  }
`
