import { Link } from "gatsby"
import React from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata.js"
import { JsonLd } from "../JsonLd"
import "./styles.scss"

const BreadcrumbsJsonLD = ({ pages }) => {
  const { baseUrl } = useSiteMetadata()

  return (
    <JsonLd
      data={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: pages.map((page, i) => ({
          "@type": "ListItem",
          item: `${baseUrl}${page.urlPath}`,
          name: page.shortTitle,
          position: i + 1,
        })),
      }}
    />
  )
}

const Breadcrumbs = ({ shortTitle, urlPath, parentPage }) => {
  const { homepageTitle } = useSiteMetadata()

  const pages = []

  let current = parentPage

  while (current) {
    pages.unshift(current)
    current = current.parentPage
  }

  pages.push({ shortTitle, urlPath })

  pages.unshift({
    shortTitle: homepageTitle,
    urlPath: "/",
  })

  return (
    <>
      <BreadcrumbsJsonLD pages={pages} />
      <ol className="breadcrumbs">
        {pages.map((page, i) => (
          <li key={i}>
            {i < pages.length - 1 ? (
              <Link to={page.urlPath}>{page.shortTitle}</Link>
            ) : (
              page.shortTitle
            )}
          </li>
        ))}
      </ol>
    </>
  )
}

export { Breadcrumbs, BreadcrumbsJsonLD }
