import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query siteMetadata {
        site {
          siteMetadata {
            defaultTitle: title
            defaultTitleTemplate
            defaultDescription: description
            siteName
            baseUrl
            defaultImage: image
            twitterUsername
            homepageTitle
          }
        }
      }
    `
  )
  return site.siteMetadata
}
